import { ErrorResponse } from '@gts-common/client-server';
import { unknownErrorMessage } from '@gts-common/client';
import { serverStatusErrors } from '@gts-ft/ui';

export function getOperationFailureReason(resp: ErrorResponse) {
  const reason = serverStatusErrors[resp.body.status];

  if (typeof reason === 'string') {
    return reason;
  }

  return unknownErrorMessage;
}
