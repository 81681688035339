import {
  convertServerDateStringToLocalDateString,
  USER_DASHBOARD_PATH,
  WidgetPrivacyGetRequestQuery,
  WidgetPrivacyReservation,
  WidgetPrivacyResponse,
} from '@gts-ft/utils';

import {
  TOKEN_LENGTH,
  ServerResponseError,
  ServerResponseErrorType,
  IndexSignatureHack,
} from '@gts-common/client-server';

import { serverComm } from '@gts-ft/ui';
import {
  getErrorModalMessage,
  getQueryParameterByName,
  KnownModalMessage,
  MessageType,
  ModalType,
  serverRequestFailed,
  serverRequestSucceeded,
  invalidLinkMessage,
  unknownLinkMessage,
  sendErrorToServer,
  showError,
} from '@gts-common/client';

import { View } from '../types';
import {
  GET_TOKEN_DATA_SUCCEEDED,
  GetTokenDataSucceededAction,
  LINK_INVALID,
  LinkInvalidAction,
  DATA_NOT_FOUND,
  DataNotFoundAction,
  Thunk,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

function getTokenDataSucceeded(data: {
  email: string;
  reservation: WidgetPrivacyReservation | undefined;
  view: View;
}): GetTokenDataSucceededAction {
  return {
    type: GET_TOKEN_DATA_SUCCEEDED,
    payload: data,
  };
}

function dataNotFound(): DataNotFoundAction {
  return {
    type: DATA_NOT_FOUND,
  };
}

function linkInvalid(): LinkInvalidAction {
  return {
    type: LINK_INVALID,
  };
}

function isUrlValid() {
  const widgetPrivacyId = getQueryParameterByName('token');
  const view = getQueryParameterByName('view');

  if (widgetPrivacyId === null) {
    return false;
  } else if (widgetPrivacyId === '') {
    return false;
  } else {
    if (view === null) {
      return false;
    } else {
      const uppercaseView = view.toUpperCase();
      return (
        widgetPrivacyId.length === TOKEN_LENGTH &&
        (uppercaseView === View.PRIVACY || uppercaseView === View.RESERVATION)
      );
    }
  }
}

const invalidLinkModal: KnownModalMessage = {
  type: MessageType.MODAL,
  modalType: ModalType.ERROR_MODAL,
  title: 'Link ungültig',
  body: invalidLinkMessage,
};

const dataNotFoundModal: KnownModalMessage = {
  type: MessageType.MODAL,
  modalType: ModalType.ERROR_MODAL,
  title: 'Link unbekannt',
  body: unknownLinkMessage,
};

export function getTokenData(): Thunk {
  return (dispatch) => {
    if (!isUrlValid()) {
      dispatch(linkInvalid());
      return dispatch(serverRequestFailed(invalidLinkModal));
    }

    // Must be a string here otherwise the url is not considered valid
    const widgetPrivacyToken = getQueryParameterByName('token') as string;
    // Must be View here otherwise the url is not considered valid
    const view = getQueryParameterByName('view') as string;
    const uppercaseView = view.toUpperCase() as View;

    return serverComm
      .execGetRequest<
        WidgetPrivacyResponse,
        IndexSignatureHack<WidgetPrivacyGetRequestQuery>
      >(USER_DASHBOARD_PATH, { token: widgetPrivacyToken })
      .then(
        (resp) => {
          if (resp.succeeded) {
            const { body } = resp;
            const reservation = body.reservation;

            let clientReservation;
            if (reservation) {
              clientReservation = {
                ...reservation,
                date: convertServerDateStringToLocalDateString(
                  reservation.date,
                ),
              };
            }

            dispatch(
              getTokenDataSucceeded({
                reservation: clientReservation,
                email: body.email,
                view: uppercaseView,
              }),
            );
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          if (e instanceof ServerResponseError) {
            if (e.type === ServerResponseErrorType.NOT_FOUND) {
              dispatch(dataNotFound());
              return dispatch(serverRequestFailed(dataNotFoundModal));
            }
          }

          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
