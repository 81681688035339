import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { addHours, isAfter, parse } from 'date-fns';
import { CLIENT_DATE_FORMAT, TIME_FORMAT } from '@gts-common/client-server';
import { App } from '../components/App';
import { ReduxState } from '../types';
import { Actions } from '../actions/reduxActionTypes';
import { cancelReservation, lockEmail, removePersonalData } from '../actions';

const mapStateToProps = (state: ReduxState) => {
  const reservation = state.userDashboard.reservation;

  let isCancelButtonDisabled = false;

  if (reservation) {
    const now = new Date();
    const reservationDatetime = parse(
      `${reservation.date} ${reservation.time}`,
      `${CLIENT_DATE_FORMAT} ${TIME_FORMAT}`,
      new Date(),
    );

    // A reservation can only be cancelled if it is later than 12 hours from now
    isCancelButtonDisabled = !isAfter(reservationDatetime, addHours(now, 12));
  }

  return {
    reservation,
    isCancelButtonDisabled,
    email: state.userDashboard.email,
    dataLoadingStatus: state.userDashboard.dataLoadingStatus,
    view: state.userDashboard.view,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execCancelReservation() {
    dispatch(cancelReservation());
  },
  execLockEmail() {
    dispatch(lockEmail());
  },
  execRemovePersonalData() {
    dispatch(removePersonalData());
  },
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
