import { initialState } from '../initialState';
import {
  Actions,
  DATA_NOT_FOUND,
  GET_TOKEN_DATA_SUCCEEDED,
  LINK_INVALID,
  RESERVATION_CANCELLED,
} from '../actions/reduxActionTypes';
import { DataLoadingStatus, ReduxState } from '../types';

export function userDashboardReducer(
  state = initialState.userDashboard,
  action: Actions,
): ReduxState['userDashboard'] {
  switch (action.type) {
    case GET_TOKEN_DATA_SUCCEEDED:
      return {
        ...state,
        reservation: action.payload.reservation,
        email: action.payload.email,
        dataLoadingStatus: DataLoadingStatus.LOADED,
        view: action.payload.view,
      };

    case DATA_NOT_FOUND:
      return {
        ...state,
        dataLoadingStatus: DataLoadingStatus.NOT_FOUND,
      };

    case LINK_INVALID:
      return {
        ...state,
        dataLoadingStatus: DataLoadingStatus.INVALID_LINK,
      };

    case RESERVATION_CANCELLED:
      if (!state.reservation) {
        throw new Error('Cannot cancel a non-existing reservation.');
      }

      return {
        ...state,
        reservation: {
          ...state.reservation,
          cancelledAt: action.payload,
        },
      };

    default:
      return state;
  }
}
