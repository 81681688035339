import { DataLoadingStatus, ReduxState, View } from './types';

export const initialState: Omit<ReduxState, 'common'> = {
  userDashboard: {
    reservation: undefined,
    email: '',
    dataLoadingStatus: DataLoadingStatus.PENDING,
    view: View.PRIVACY,
  },
};
