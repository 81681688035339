import {
  Footer,
  MessagesContainer,
  SimpleHeader,
  PleaseWaitText,
  UnknownLinkText,
  InvalidLinkText,
} from '@gts-common/client';

import { Box, Container } from '@material-ui/core';
import { WidgetPrivacyReservation } from '@gts-ft/utils';
import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';
import { DataLoadingStatus, View } from '../types';
import logo from '../images/logo.png';
import { Privacy } from './Privacy';
import { Reservation } from './Reservation';

interface Props {
  reservation: WidgetPrivacyReservation | undefined;
  isCancelButtonDisabled: boolean;
  email: string;
  dataLoadingStatus: DataLoadingStatus;
  view: View;
  execCancelReservation: () => void;
  execLockEmail: () => void;
  execRemovePersonalData: () => void;
}

export const App = ({
  reservation,
  isCancelButtonDisabled,
  email,
  dataLoadingStatus,
  view,
  execCancelReservation,
  execLockEmail,
  execRemovePersonalData,
}: Props) => {
  return (
    <div>
      <SimpleHeader logoSrc={logo} title="free-table" />
      <Container style={{ marginBottom: '80px' }}>
        {(function getContent() {
          if (dataLoadingStatus === DataLoadingStatus.NOT_FOUND) {
            return <UnknownLinkText />;
          } else if (dataLoadingStatus === DataLoadingStatus.INVALID_LINK) {
            return <InvalidLinkText />;
          } else if (dataLoadingStatus === DataLoadingStatus.LOADED) {
            if (view === View.PRIVACY) {
              return (
                <Privacy
                  email={email}
                  execLockEmail={execLockEmail}
                  execRemovePersonalData={execRemovePersonalData}
                />
              );
            } else {
              return (
                <Reservation
                  reservation={reservation}
                  isCancelButtonDisabled={isCancelButtonDisabled}
                  execCancelReservation={execCancelReservation}
                />
              );
            }
          } else {
            return (
              <Box mt="2rem">
                <PleaseWaitText />
              </Box>
            );
          }
        })()}
      </Container>
      <Footer domain={getUrl(ServiceCode.FREE_TABLE, UrlType.FRONT_PAGE)} />
      <MessagesContainer />
    </div>
  );
};
