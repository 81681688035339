import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { CommonActions } from '@gts-common/client';
import { WidgetPrivacyReservation } from '@gts-ft/utils';
import { ReduxState, View } from '../types';

export const GET_TOKEN_DATA_SUCCEEDED = 'GET_TOKEN_DATA_SUCCEEDED';
export const DATA_NOT_FOUND = 'DATA_NOT_FOUND';
export const LINK_INVALID = 'LINK_INVALID';

export const RESERVATION_CANCELLED = 'RESERVATION_CANCELLED';

export interface GetTokenDataSucceededAction extends Action {
  type: typeof GET_TOKEN_DATA_SUCCEEDED;
  payload: {
    email: string;
    reservation: WidgetPrivacyReservation | undefined;
    view: View;
  };
}

export interface DataNotFoundAction extends Action {
  type: typeof DATA_NOT_FOUND;
}

export interface LinkInvalidAction extends Action {
  type: typeof LINK_INVALID;
}

export interface ReservationcancelledAction extends Action {
  type: typeof RESERVATION_CANCELLED;
  payload: string;
}

export type Actions =
  | GetTokenDataSucceededAction
  | DataNotFoundAction
  | LinkInvalidAction
  | ReservationcancelledAction
  | CommonActions;

export type Thunk = ThunkAction<void, ReduxState, unknown, Actions>;
