import { Box, Typography } from '@material-ui/core';
import { Button } from '@gts-common/client';

interface PrivacyProps {
  email: string;
  execLockEmail: () => void;
  execRemovePersonalData: () => void;
}

export const Privacy = ({
  email,
  execLockEmail,
  execRemovePersonalData,
}: PrivacyProps) => {
  return (
    <>
      <Box component="section" mt="1rem">
        <Typography variant="h6" component="h2" gutterBottom={true}>
          Sperrung meiner E-Mail-Adresse
        </Typography>
        <Typography variant="body2" align="justify">
          Falls Sie Ihre E-Mail-Adresse ({email}) für die weitere Verwendung im
          Rahmen unserer Webanwendung sperren möchten, klicken Sie bitte auf den
          folgenden Button. Sie werden in diesem Fall auch dann keine weitere
          Nachricht erhalten, wenn Ihre E-Mail-Adresse erneut eingegeben wird.
          Bitte bedenken Sie, dass die E-Mail-Adresse in diesem Fall nicht mehr
          für eine Reservierung in einem unserer angeschlossenen Restaurants
          verwendet werden kann.
        </Typography>
        <Box mt="1rem">
          <Button onClick={execLockEmail} label="E-Mail-Adresse sperren" />
        </Box>
      </Box>
      <Box component="section" mt="1rem">
        <Typography variant="h6" component="h2" gutterBottom={true}>
          Widerruf meiner Einwilligung / Löschung der Daten
        </Typography>
        <Typography variant="body2" align="justify">
          Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die
          Zukunft widerrufen. Ein entsprechender Button befindet sich unterhalb
          dieses Absatzes. Der Widerruf hat auf die Rechtmäßigkeit der
          Verarbeitungen bis zum Zeitpunkt des Widerrufs keinen Einfluss. Um
          Ihre Daten zu finden, verwenden wir die E-Mail-Adresse ({email}) die
          Sie bei der Reservierung angegeben haben. Wenn Sie Ihre Einnwilligung
          widerrufen werden wir alle zukünftige Reservierungen, die diese
          E-Mail-Adresse verwenden stornieren und Ihre persönliche Daten
          löschen. Wir werden auch Ihre persönliche Daten von allen
          Reservierungen, die in der Vergangenheit liegen und die angegebene
          E-Mail-Adresse verwenden löschen.
        </Typography>
        <Box mt="1rem">
          <Button
            onClick={execRemovePersonalData}
            label="Einwilligung widerrufen"
          />
        </Box>
      </Box>
    </>
  );
};
