import { CommonState } from '@gts-common/client';
import { WidgetPrivacyReservation } from '@gts-ft/utils';

export interface ReduxState {
  userDashboard: {
    reservation: WidgetPrivacyReservation | undefined;
    email: string;
    dataLoadingStatus: DataLoadingStatus;
    view: View;
  };
  common: CommonState;
}

export enum DataLoadingStatus {
  PENDING = 'PENDING',
  INVALID_LINK = 'INVALID_LINK',
  NOT_FOUND = 'NOT_FOUND',
  LOADED = 'LOADED',
}

export enum View {
  RESERVATION = 'RESERVATION',
  PRIVACY = 'PRIVACY',
}
