import { EMAIL_LOCK_PATH, LockEmailRequestBody } from '@gts-ft/utils';

import { serverComm } from '@gts-ft/ui';
import {
  getErrorModalMessage,
  getQueryParameterByName,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { Thunk } from './reduxActionTypes';

export function lockEmail(): Thunk {
  return (dispatch) => {
    dispatch(startServerRequest());

    // If the token is not set then the URL is not valid
    const token = getQueryParameterByName('token') as string;

    return serverComm
      .execPostRequest<Record<string, never>, LockEmailRequestBody>(
        EMAIL_LOCK_PATH,
        { token },
      )
      .then(
        () => {
          dispatch(serverRequestSucceeded('E-Mail-Adresse gesperrt.'));
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
