import {
  USER_CANCEL_RESERVATION_PATH,
  WidgetCancelReservationRequestBody,
} from '@gts-ft/utils';
import { format } from 'date-fns';

import { serverComm } from '@gts-ft/ui';
import {
  getErrorModalMessage,
  getQueryParameterByName,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { SERVER_DATE_FORMAT, TIME_FORMAT } from '@gts-common/client-server';
import {
  RESERVATION_CANCELLED,
  ReservationcancelledAction,
  Thunk,
} from './reduxActionTypes';
import { getOperationFailureReason } from './helpers/getOperationFailureReason';

function reservationcancelled(): ReservationcancelledAction {
  const date = format(new Date(), `${SERVER_DATE_FORMAT} ${TIME_FORMAT}`);
  return {
    type: RESERVATION_CANCELLED,
    payload: date,
  };
}

export function cancelReservation(): Thunk {
  return (dispatch) => {
    dispatch(startServerRequest());

    // If the token is not set then the URL is not valid
    const token = getQueryParameterByName('token') as string;

    return serverComm
      .execPostRequest<
        Record<string, never>,
        WidgetCancelReservationRequestBody
      >(USER_CANCEL_RESERVATION_PATH, { token })
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(reservationcancelled());
            dispatch(serverRequestSucceeded('Reservierung storniert.'));
          } else {
            dispatch(serverRequestFailed(getOperationFailureReason(resp)));
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
